import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { ModalDirective } from "ngx-bootstrap/modal"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionMultiple,
    EvaluacionMultiples,
    GrupoUsuarios,
    Instrumento,
    InstrumentoMultiple
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "opciones-instrumento-multiple",
    templateUrl: "./opciones-instrumento-multiple.component.html",
    styleUrls: ["./opciones-instrumento-multiple.component.scss"]
})
export class OpcionesInstrumentoMultipleComponent implements OnInit {
    @Input() instrumentoMultiple: InstrumentoMultiple
    @Input() tipoEvaluacion: string
    @Input() evaluacionMultipleId: number
    @Input() redirect: string
    @Input() compartirTodosCursos: boolean
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective
    canceled: boolean = false
    errorMsg: boolean = false

    ultimaEvaluacionMultipleId: number
    ultimaEvaluacionInstrumento: Instrumento
    ultimaEvaluacionId: number
    ultimaEvaluacionIndex: number
    imprimirAlCompartir: boolean = false
    hideCompartirImprimir: boolean = false
    hideOpcionesImpresion: boolean = false
    @Input() enableAdminOptions: boolean = false

    constructor(
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected evaluacionMultiplesService: EvaluacionMultiples
    ) {}

    ngOnInit() {
        if (
            !this.tipoEvaluacion &&
            (!this.instrumentoMultiple.generador_instrumento ||
                !this.instrumentoMultiple.generador_instrumento.tipo_instrumento)
        ) {
            return
        }
        this.tipoEvaluacion =
            this.tipoEvaluacion || this.instrumentoMultiple.generador_instrumento.tipo_instrumento.tipo_instrumento

        if (config.plataforma.hideCompartirImprimirEvaluacionMulti !== undefined) {
            this.hideCompartirImprimir = config.plataforma.hideCompartirImprimirEvaluacionMulti
        }
        if (config.plataforma.hideOpcionesImpresionEvaluacionMulti !== undefined) {
            this.hideOpcionesImpresion = config.plataforma.hideOpcionesImpresionEvaluacionMulti
        }
    }

    onEvaluacionMultipleCompartida(e: EvaluacionMultiple) {
        this.ultimaEvaluacionMultipleId = e.id
        this.evaluacionMultipleId = this.ultimaEvaluacionMultipleId
        if (this.imprimirAlCompartir) {
            this.imprimirTodos("alumno")
        }
    }

    // onEvaluacionesCompartidas(evaluaciones: Evaluacion[]) {
    // 	this.ultimasEvaluacionesIds = evaluaciones.map(e => e.id);
    // 	if(this.imprimirAlCompartir) {
    // 		this.imprimirTodos("alumno")
    // 	}
    // }

    imprimirTodos(perfil: string = null) {
        let params = {
            render_options: {
                include: {
                    evaluaciones: {
                        include: {
                            instrumento: {
                                include: { generador_instrumento: null }
                            }
                        }
                    }
                }
            }
        }

        this.evaluacionMultiplesService
            .find(this.evaluacionMultipleId, params)
            .then((evaluacionMultiple: EvaluacionMultiple) => {
                if (evaluacionMultiple.evaluaciones) {
                    let time = 0
                    evaluacionMultiple.evaluaciones.forEach((evaluacion, i) => {
                        this.ultimaEvaluacionId = evaluacion.id
                        this.ultimaEvaluacionInstrumento = evaluacion.instrumento
                        this.ultimaEvaluacionIndex = i + 1
                        this.imprimir(perfil, (time = time))
                        time += 1500
                    })
                }
            })
    }

    imprimirHojasTodos() {
        let params = {
            include: "[evaluaciones]"
        }
        this.evaluacionMultiplesService
            .find(this.evaluacionMultipleId, params)
            .then((evaluacionMultiple: EvaluacionMultiple) => {
                if (evaluacionMultiple) {
                    let time = 0
                    evaluacionMultiple.evaluaciones.forEach(evaluacion => {
                        this.ultimaEvaluacionId = evaluacion.id
                        this.imprimirHojas((time = time))
                        time += 1500
                    })
                }
            })
    }

    imprimir(perfil: string = null, time = 0) {
        this.canceled = false
        this.errorMsg = false
        this.mandatoryLoading.show()
        let params: any = {}
        if (this.instrumentoMultiple?.generador_instrumento?.maximo_alternativas) {
            params.cantidad_alternativas = this.instrumentoMultiple.generador_instrumento.maximo_alternativas
        }
        params.plataforma = config.plataforma.name

        if (perfil == "profesor") {
            params.profesor = 1
            let tipoEval = this.ultimaEvaluacionInstrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
            params.group_by = config.evaluaciones[tipoEval].clasificaciones.groupBy
            params.clasificacion_tipos_tabla_spec =
                config.evaluaciones[tipoEval].clasificaciones.clasificacionTiposTablaEspecificaciones
        } else if (perfil == "alumno") {
            params.alumno = 1
        }

        let evaluacionId = this.ultimaEvaluacionId

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.enableIgnoreCatch()
        this.evaluacionesService
            .imprimir(evaluacionId, params)
            .then((obj: any) => {
                if (this.canceled) {
                    throw new Error("Cancelado")
                }
                this.evaluacionesService.disableIgnoreModel()
                const info = obj.info
                this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                this.mandatoryLoading.hide()

                const downloadString = "evaluacion_" + evaluacionId + ".pdf"

                this.createLinkAndOpen(info.link, downloadString, (time = time))
            })
            .catch(response => {
                this.evaluacionesService.disableIgnoreModel()
                if (!this.canceled) {
                    this.errorMsg = true
                }
            })
    }

    logInfoLatex(statusCodeLambda, exitStatus, stdout, stderr) {
        console.log("STATUS CODE LAMBDA")
        console.log("%c " + statusCodeLambda, "color: blue")
        console.log("EXIT STATUS LATEX")
        console.log("%c " + exitStatus, "color: blue")
        console.log("SALIDA ESTANDAR LATEX")
        console.log("%c " + stdout, "color: blue")
        console.log("SALIDA DE ERRORES LATEX")
        console.log("%c " + stderr, "color: crimson")
    }

    createLinkAndOpen(link, downloadString, time = 0) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        setTimeout(() => {
            a.click()
        }, time)
    }

    imprimirHojas(time = 0) {
        this.canceled = false
        this.errorMsg = false
        this.mandatoryLoading.show()
        let params: any = {}
        params.plataforma = config.plataforma.name

        let evaluacionId = this.ultimaEvaluacionId

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.enableIgnoreCatch()
        this.evaluacionesService
            .imprimirHojas(evaluacionId, params)
            .then((obj: any) => {
                if (this.canceled) {
                    throw "Cancelado"
                }
                this.evaluacionesService.disableIgnoreModel()
                const info = obj.info
                this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                this.mandatoryLoading.hide()
                const downloadString = "hoja_respuestas_" + evaluacionId + ".pdf"
                this.createLinkAndOpen(info.link, downloadString, (time = time))
            })
            .catch(response => {
                this.evaluacionesService.disableIgnoreModel()
                if (!this.canceled) {
                    this.errorMsg = true
                }
            })
    }

    cancelPrint() {
        this.evaluacionesService.disableIgnoreModel()
        this.mandatoryLoading.hide()
        this.canceled = true
    }

    fallbackPrint() {
        let evaluacionId = this.ultimaEvaluacionId
        let originalTitle = document.title
        document.title = "evaluacion_" + evaluacionId + ".pdf"
        document.getElementById("tab" + this.ultimaEvaluacionIndex + "-link").click()
        setTimeout(() => {
            window.print()
            document.title = originalTitle
            this.mandatoryLoading.hide()
        }, 300)
    }
}
